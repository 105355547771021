<template>
  <div class="home">

      <h1>Blogroll</h1>
      <h2>Blogs mit Bezug zu den Themen Kochen und Nahrungsmitteln </h2>
  <ul>
    <li><a href="http://www.imkerei-rotaru.de/">Wissenswertes Rund um leckeren Honig aus der Imkerei Rotaru</a></li>
          </ul>
          <h2>Diverse Blogs</h2>
   <ul>
      <li><a href="http://www.developersblog.de/">Developersblog</a></li>
            <li><a href="http://www.shopblogger.de/blog/">Shopblogger</a></li>
             <li><a href="http://www.bestatterweblog.de/">Bestatterweblog</a></li>
             <li><a href="http://www.frostmann.de/">Frostmann</a></li>
         </ul>
  </div>
</template>

<script>
export default {
  name: 'BlogRoll',
}
</script>
